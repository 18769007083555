<template>
  <div
    class="user-card card"
  >
    <div
      class="p-3 card-body"
    >
      <div 
        class="d-flex mt-n2"
      >
        <div 
          class="my-auto ms-3 w-100"
          @click.prevent="select"

        >
          <h6 
            class="mb-0"
          >
            {{ title }}
          </h6>
          <p
            class="mb-0"
          >
            <small>
              {{ memberCount }} leden
            </small>
          </p>
        </div>
        <div class="ms-auto">
          <dropdown-menu
            :items="[
              {
                handler: remove,
                name: 'Verwijderen',
              },
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { removeGroup } from '@/api/providers/groups';
import { createYesNoSweetAlert } from '@/helpers/sweetalert';
import DropdownMenu from '@/components/UI/DropdownMenu.vue';

export default {
  name: 'UserCard',

  components: {
    DropdownMenu,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    
    title: {
      type: String,
      required: true,
    },
    
    memberCount: {
      type: Number,
      required: true
    }
  },

  emits: [
    'selected',
    'removed',
  ],

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    edit() {
      this.$router.push({
        name: 'edit-app-user',
        params: {
          id: this.id
        },
      });
    },

    remove() {
      createYesNoSweetAlert(
        `Groep ${this.title} verwijderen?`,
        async () => {
          try {
            await removeGroup(this.id);
            this.$emit('removed', this.title);
          }
          catch {
            this.addNotification({
              description: `Er was een fout bij het verwijderen van de groep.`,
              icon: {
                component: 'cancel',
              },
            });
          }
        }
      );
    },

    select() {
      this.$emit('selected', this.id)
    }
  },
};
</script>

<style scoped lang="scss">
.user-card {
  height: 100%;

  &__link {
    display: grid;
    place-content: center;
  }

  &__image {
    object-fit: cover;
  }
}
</style>
