<template>
  <form
    class="filter-bar sticky-top"
    :class="[
      { 'filter-bar--visible': showFilters },
      { 'mb-3': showFilters },
    ]"
  >
    <div class="d-flex p-2 bg-body rounded shadow">
      <div class="d-flex flex-wrap gap-2">
        <checkbox-filter
          v-model="filters.isActivated"
          name="Actief"
          :options="new Map([
            [true, 'Ja'],
            [false, 'Nee'],
          ])"
        />
      </div>

      <div class="col-3 ms-auto">
        <material-input
          id="app-users-search"
          v-model="searchQuery"
          placeholder="Zoeken..."
          type="search"
        />
      </div>
    </div>
  </form>

  <div
    v-for="group in filteredItems"
    :key="group.id"
    class="mt-3 mb-3 col-lg-4 col-md-6"
  >
    <group-card
      :id="group.id"
      :title="group.title"
      :member-count="group.userCount"
      @selected="onGroupCardSelected"
      @removed="onGroupRemoved"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getAllGroups } from '@/api/providers/groups';
import { getAll as getAllUsers } from '@/api/providers/users';

import MaterialInput from '@/components/MaterialInput.vue';
import GroupCard from '../../components/Groups/GroupCard.vue';

import CheckboxFilter from '@/components/UI/CheckboxFilter.vue';

import initializeSearchBarFocusEvent from '../../helpers/search-bar';

export default {
  components: {
    CheckboxFilter,
    GroupCard,
    MaterialInput
  },

  data: () => ({
    filters: {
      isActivated: [],
    },
    items: [],
    searchQuery: '',
    showFilters: false,

    editGroupModalTitle: '',
  }),

  computed: {
    filteredItems() {
      return this.items
        .filter(this.searchFilter)
        .filter(this.filtersFilter);
    },

    lowerCaseSearchTerms() {
      return this.searchQuery.toLowerCase().split(' ');
    },
  },

  mounted() {
    this.loadGroups();
    this.addActions();

    initializeSearchBarFocusEvent(
      () => this.showFilters = true
    );
  },

  methods: {
    ...mapActions('appHeader', [
      'addAction',
    ]),

    ...mapActions('notification', {
      addNotification: 'add',
    }),

    addActions() {
      this.addAction({
        handler: () => this.$router.push('/groups/new'),
        icon: 'group_add',
        text: 'Nieuwe groep aanmaken',
      });
    },

    filtersFilter(item) {
      return Object.entries(this.filters)
        .every(([property, values]) => (
          values.includes(item[property])
          || !values.length
        ));
    },

    async loadGroups() {
      try {
        this.items = await getAllGroups() ?? [];
      }
      catch (error) {
        if(error.default != undefined) {
          error.default();
        }
      }
    },

    onGroupRemoved(title) {
      this.addNotification({
        title,
        description: `Groep verwijderd.`,
        icon: {
          component: 'group',
        },
      });
      this.loadGroups();
    },

    searchFilter(item) {
      return !this.searchQuery.length
        ? true
        : Object.values(item)
            .filter((value) => {
              if (typeof value !== 'string') {
                return false;
              }

              const lowerCaseValue = value.toLowerCase();

              return this.lowerCaseSearchTerms
                .filter(
                  (lowerCaseSearchTerm) => lowerCaseValue.includes(lowerCaseSearchTerm)
                ).length;
            }).length;
    },

    showNewGroupForm() {
      this.$refs.addGroupModalRef.show();
    },

    async loadDropdownOptions() {
      var apiUsers = await getAllUsers();
      
      this.dropdownOptions = apiUsers.map(u => {
        return {
          value: u.id, 
          label: `${u.firstName ?? ''} ${u.insertion ?? ''} ${u.lastName ?? ''}.`,
          image: u.profilePicture
        };
      });
    },

    onNewGroupFormSubmit() {
      this.loadGroups();
      this.$refs.addGroupModalRef.hide();
    },

    onEditGroupFormSubmit() {
      this.loadGroups();
      this.$refs.editGroupModalRef.hide();
    },

    async onGroupCardSelected(groupId) {
      this.$router.push(`/groups/${groupId}`);
    }
  },
};
</script>

<style scoped lang="scss">
.filter-bar {
  height: 0;
  transition-property: height margin opacity;
  transition-duration: .2s;

  &:not(&--visible) {
    overflow: hidden;
    opacity: 0;
  }

  &--visible {
    height: 56.19px;
  }
}
</style>
